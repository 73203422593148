import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useAccount } from '../../lib/account';
import { useRepo } from '../../lib/repository';
import { Button, PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import Input from '../inputs/Input';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { broadcastSuccessToast } from '../Toasts';

const teamSchema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

const useTeamCreateMutation = () => {
  const repo = useRepo();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id: accountId } = useAccount();
  return useMutation(({ name }: { name: string }) => repo.createTeam(accountId, name), {
    onSuccess: () => {
      queryClient.invalidateQueries('teams');
      broadcastSuccessToast(t('teamCreated'));
    },
  });
};

const TeamCreatePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const mutation = useTeamCreateMutation();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <LayoutWithSidebar>
      <div className="flex flex-col flex-grow">
        <HeaderWithBack backHandler={handleBack}>{t('untitledTeam')}</HeaderWithBack>
        <Formik
          initialValues={{ name: '' }}
          onSubmit={(values, formik) => {
            mutation.mutate(values, {
              onSuccess: (team) => {
                history.push(`/team/${team.id}`);
              },
              onError: () => {
                // TODO Handle error.
              },
              onSettled: () => {
                formik.setSubmitting(false);
              },
            });
          }}
          validationSchema={teamSchema}
          validateOnMount
        >
          {({ isValid, isSubmitting }) => {
            const canSubmit = isValid && !isSubmitting;
            return (
              <Form className="flex flex-col flex-grow mt-2">
                <div className="flex-grow space-y-8">
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('name')}</div>
                      <div>
                        <Field as={Input} name="name" />
                      </div>
                    </label>
                  </div>
                  {/** <div className="w-96">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                    <div>{t('Description')}</div>
                    <div className="ml-6 text-gray-500">{t('Optional')}</div>
                  </div>
                  <div>
                    <Textarea />
                  </div>
                </label>
              </div> */}
                </div>

                <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
                  <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                    {t('save')}
                  </PrimaryButton>
                  <Button onClick={handleBack}>{t('discard')}</Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </LayoutWithSidebar>
  );
};

export default TeamCreatePage;
