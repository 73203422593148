import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { i18n } from 'i18next';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import successSrc from '../assets/success.svg';
import { ReactComponent as Sunset } from '../assets/sunset.svg';
import { hasPolicy, isTrialExpired, useAccount } from '../lib/account';
import { useUser } from '../lib/auth';
import { brandName } from '../lib/flavour';
import { usePermissionChecker } from '../lib/hooks';
import { getErrorMessage } from '../lib/i18n';
import { isDomainAdminOf, isSuperUser } from '../lib/perms';
import { useRepo } from '../lib/repository';
import { AccountState, AccountSupportType, Permission } from '../lib/types';
import { classNames } from '../lib/utils';
import { setLastAccountId } from './AccountNegotiator';
import { LinkPrimaryButton, PrimaryButton } from './Buttons';
import { NotificationError } from './Notifications';
import CheckIcon from './icons/CheckIcon';
import OutsideAccountLayout from './layouts/OutsideAccount';
import WithoutSidebar from './layouts/WithoutSidebar';
import Spinner from './widgets/Spinner';

const AccountBillingGate: React.FC = ({ children }) => {
  const account = useAccount();
  const permChecker = usePermissionChecker();

  const canManageBilling = permChecker.hasAccountPermission(Permission.ManageBilling);
  const useBilling = hasPolicy(account, 'use_self_billing');
  const canUpgrade = useBilling && canManageBilling;

  return (
    <Switch>
      {canUpgrade ? (
        <Route path="/plans">
          <WithoutSidebar backTo="/">
            <StripeWrapper>
              <AccountPlans />
            </StripeWrapper>
          </WithoutSidebar>
        </Route>
      ) : null}
      <Route>
        <AccountBillingGateContent>{children}</AccountBillingGateContent>
      </Route>
    </Switch>
  );
};

export default AccountBillingGate;

const AccountBillingGateContent: React.FC = ({ children }) => {
  const user = useUser();
  const account = useAccount();
  const permChecker = usePermissionChecker();

  const isSuspended = account.state === AccountState.Suspended;
  const canManageBilling = permChecker.hasAccountPermission(Permission.ManageBilling);
  const hasTrialExpired = isTrialExpired(account);
  const isAccessPrevented = isSuspended || hasTrialExpired;
  const useBilling = hasPolicy(account, 'use_self_billing');
  const canUpgrade = useBilling && canManageBilling;

  if (isAccessPrevented && !isSuperUser(user) && !isDomainAdminOf(user, account)) {
    if (!canManageBilling) {
      return <AccountSuspendedBlockedPage />;
    } else if (hasTrialExpired) {
      return <AccountTrialExpiredPage canUpgrade={canUpgrade} />;
    }
    return <AccountSuspendedBlockedPage />;
  }

  return <>{children}</>;
};

function valueOrUnlimited(i18n: i18n, value: number) {
  if (value < 0) {
    return i18n.t('unlimited');
  }
  return value;
}

function yesOrNo(i18n: i18n, value: boolean) {
  return value ? (
    <div className="inline-block">
      <CheckIcon className="text-[#adcf44]" />
      <span className="sr-only">{i18n.t('yes')}</span>
    </div>
  ) : (
    <span className="sr-only">{i18n.t('no')}</span>
  );
}

const AccountSuspendedBlockedPage = () => {
  const { t } = useTranslation();
  return (
    <WithoutSidebar>
      <div className="mt-8 flex flex-col items-center justify-center max-w-lg m-auto">
        <div>
          <Sunset className="" />
        </div>
        <h3 className="mt-6 text-2xl font-medium">{t('yourAccountIsSuspended')}</h3>
        <p className="mt-3 text-center">{t('accountSuspendedContactYourAccountManager')}</p>
      </div>
    </WithoutSidebar>
  );
};

const AccountTrialExpiredPage = ({ canUpgrade }: { canUpgrade: boolean }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <WithoutSidebar>
      <div className="mt-8 flex flex-col items-center justify-center max-w-lg m-auto">
        <div>
          <Sunset className="" />
        </div>
        <h3 className="mt-6 text-2xl font-medium">{t('yourTrialHasExpired')}</h3>
        <p className="mt-3 text-center">
          {canUpgrade ? t('ifYouEnjoyFeaturesUpgradeToPaidPlan') : t('trialExpiresContactYourAccountManager')}
        </p>
        {canUpgrade ? (
          <div className="mt-6 flex items-center">
            <div className=" max-w-sm">
              <PrimaryButton onClick={() => history.push('/plans')}>{t('showMeThePlans')}</PrimaryButton>
            </div>
          </div>
        ) : null}
      </div>
    </WithoutSidebar>
  );
};

const AccountPlans = () => {
  const { t: _, i18n } = useTranslation();
  const repo = useRepo();
  const account = useAccount();
  const query = useQuery(['account', account.id, 'available-plans'], () => repo.getAccountAvailablePlans(account.id));

  if (query.isLoading || !query.data) {
    return null;
  } else if (query.isError) {
    return <NotificationError>{getErrorMessage(query.error)}</NotificationError>;
  }
  const plans = query.data;

  return (
    <div className="text-center mt-4">
      <h1 className=" text-5xl">{_('billing.upgradeYourAccount')}</h1>
      <p className="text-2xl mt-4">{_('billing.continueToAccessGreatFeatures')}</p>
      <div className="mt-6">
        <table className="w-full text-center m-auto billing-plans-table bg-white">
          <tbody>
            <tr className="titles">
              <td className="text-left w-64"></td>
              {plans.map((plan, idx) => {
                const { name } = plan;
                const price = plan.yearly_cost;
                return (
                  <td key={idx}>
                    <div className="text-gray-500 text-2xl font-bold">{name}</div>
                    <div className="mt-2 mb-4 mx-auto border-b-2 border-silver-3 w-24"></div>
                    <div className={classNames(!price ? 'invisible' : '')}>
                      <div className="flex items-start justify-center">
                        <span className="text-gray-500 mt-1">US$</span>
                        <span className="text-5xl">{price || ' '}</span>
                      </div>
                      <div className="text-xs text-gray-500">{_('billing.slashYear')}</div>
                    </div>
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="text-left">{_('billing.users')}¹</td>
              {plans.map((p, idx) => (
                <td key={idx}>{p.id !== 'custom' ? p.policy.max_users : _('billing.tiered')}</td>
              ))}
            </tr>
            <tr>
              <td className="text-left">{_('billing.teams')}²</td>
              {plans.map((p, idx) => (
                <td key={idx}>{valueOrUnlimited(i18n, p.policy.max_sections)}</td>
              ))}
            </tr>
            <tr>
              <td className="text-left">{_('billing.incentiveStores')}³</td>
              {plans.map((p, idx) => (
                <td key={idx}>{valueOrUnlimited(i18n, p.policy.max_stores)}</td>
              ))}
            </tr>
            <tr>
              <td className="text-left">{_('billing.incentivesPerStore')}</td>
              {plans.map((p, idx) => (
                <td key={idx}>{valueOrUnlimited(i18n, p.policy.max_items_per_store)}</td>
              ))}
            </tr>
            <tr>
              <td className="text-left">{_('billing.api')}</td>
              {plans.map((p, idx) => (
                <td key={idx}>{yesOrNo(i18n, p.policy.use_api)}</td>
              ))}
            </tr>
            <tr>
              <td className="text-left">{_('billing.numOfEvents')}</td>
              {plans.map((p, idx) => (
                <td key={idx}>{p.id !== 'custom' ? valueOrUnlimited(i18n, -1) : _('billing.singleSlashUnlimited')}</td>
              ))}
            </tr>
            <tr>
              <td className="text-left">{_('billing.support')}</td>
              {plans.map((p, idx) => (
                <td key={idx}>
                  {p.support.includes(AccountSupportType.AccountManager)
                    ? _('billing.accountManager')
                    : _('billing.ticketsSlashCall')}
                </td>
              ))}
            </tr>
            <tr>
              <td className="text-left"></td>
              {plans.map((p, idx) => (
                <td key={idx}>
                  <div className="py-4">
                    <PrimaryButton onClick={() => window.open('https://calendly.com/motrain-incentli/incentli-chat')}>
                      {_('contactUs')}
                    </PrimaryButton>
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="my-8 text-xs text-left">
          {[
            '¹ ' + _('billing.footNotes.usersReplacableEachEvent'),
            '² ' + _('billing.footNotes.teamsAreSegmentOfUsers'),
            '³ ' + _('billing.footNotes.exhibitorsCanBeAssignedStore'),
          ].map((note, idx) => {
            return (
              <p className="m-0" key={idx}>
                {note}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const StripeWrapper: React.FC = (props) => {
  const [s] = useState(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''));
  return <Elements stripe={s}>{props.children}</Elements>;
};

export const StripeCheckoutCallbackPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const repo = useRepo();
  const queryClient = useQueryClient();

  const qs = queryString.parse(location.search);
  const accountId = qs.account_id;
  const sessionId = qs.session_id;

  const mutation = useMutation(
    async ({ accountId, sessionId }: { accountId: string; sessionId: string }) => {
      return repo.completeAccountSubscriptionViaStripeCheckout(accountId, sessionId);
    },
    {
      onSuccess: (account, { accountId }) => {
        // Preload the cache query.
        queryClient.setQueryData(['account', accountId], account);
      },
      onSettled: (data, err, { accountId }) => {
        // Affirm the fact that this account should be loaded, although it shouldn't be needed.
        setLastAccountId(accountId);
      },
    }
  );

  useEffect(() => {
    mutation.mutate({ accountId: (accountId || '').toString(), sessionId: (sessionId || '').toString() });
  }, [sessionId, accountId]); // eslint-disable-line

  return (
    <OutsideAccountLayout>
      <div className="flex-grow flex justify-center items-center">
        <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow">
          {mutation.isIdle || mutation.isLoading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : null}
          {mutation.isError ? (
            <>
              <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
              <div className="mt-4 inline-block">
                <LinkPrimaryButton to="/">{t('continue')}</LinkPrimaryButton>
              </div>
            </>
          ) : null}
          {mutation.isSuccess ? (
            <div className="text-center flex flex-col justify-center">
              <h1 className="text-3xl font-bold">{t('billing.paymentSuccessful')}</h1>
              <p className="mt-4 text-2xl">{t('billing.thankYouForSigningUpFor', { product: brandName })}</p>
              <div className="my-12 flex justify-center">
                <img src={successSrc} alt="" />
              </div>
              <p>
                <LinkPrimaryButton to="/">{t('continue')}</LinkPrimaryButton>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </OutsideAccountLayout>
  );
};
