import { getUnixTime } from 'date-fns';
import React, { useContext } from 'react';
import { AccountPermissionsExtended } from './perms';
import { Account, AccountMembership, AccountPaymentType, AccountPolicy, AccountState, Team } from './types';

export const AccountContext = React.createContext<{
  account?: Account;
  isLoading: boolean; // Whether we're attempting to load an account.
  membership?: AccountMembership;
  permissions?: AccountPermissionsExtended;
  setAccount: (account: Account) => void;
  // Patch the account. If replace is true, the values will entirely replace the account. This should only
  // be used when we want to replace the whole account with the values provided, which would therefore not be partial.
  patchAccount: (values: Partial<Account>, replace?: boolean) => void;
  refreshAccount: () => void;
  unsetAccount: () => void;
}>({
  isLoading: true,
  setAccount: () => {},
  patchAccount: () => {},
  refreshAccount: () => {},
  unsetAccount: () => {},
});

export const useAccount = (): Account => {
  const { account } = useContext(AccountContext);
  if (!account) throw new Error('Should not be used here!');
  return account;
};

export const hasPolicy = (account: Account, policy: keyof AccountPolicy) => {
  return Boolean(account.policy[policy]);
};

export const hasTicketsEnabled = (account: Account) => {
  return hasPolicy(account, 'use_tickets') && account.tickets_enabled;
};

export const hasTicketsEnabledInTeam = (account: Account, team: Team) => {
  return team.tickets_enabled && hasTicketsEnabled(account);
};

export const hasVoucherMethodEnabled = (account: Account) => {
  return hasPolicy(account, 'use_voucher_method');
};

export const isTrialing = (account: Account) => {
  return account.payment_type === AccountPaymentType.Trial;
};

export const isTrialExpired = (account: Account) => {
  if (!account || account.payment_type !== AccountPaymentType.Trial) {
    return false;
  }
  return account.state === AccountState.Suspended || account.expiry < getUnixTime(new Date());
};
