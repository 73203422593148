import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccount } from '../../lib/account';
import { usePermissionChecker } from '../../lib/hooks';
import { Permission } from '../../lib/types';
import Notification, { NotificationError } from '../Notifications';
import { HasPolicy } from '../Policy';

const THRESHOLDS = [0.05, 0.1, 0.15, 0.2];
const WARNING_THRESHOLD = THRESHOLDS[THRESHOLDS.length - 1];

export const PlayerQuotaWarning = () => {
  const { t } = useTranslation();
  const account = useAccount();
  const permChecker = usePermissionChecker();

  // We need at least the ReadPlayer permission somewhere to see the warning or error message.
  if (!permChecker.hasAnywhereInAccountPermission(Permission.ReadPlayer)) {
    return null;
  }

  // Unlimited players.
  if (account.policy.max_users <= 0) {
    return null;
  }

  // Account has not yet met the consumption threshold for a warning.
  const usageRatio = Math.min(1, account.cur_users / account.policy.max_users);
  const remainingRatio = Math.ceil(Math.max(0, 1 - usageRatio) * 100) / 100; // Round up or can trigger early.
  if (remainingRatio > WARNING_THRESHOLD) {
    return null;
  }

  // Everyone that has ReadPlayer permission anywhere can see the message when the quota is fully used.
  if (remainingRatio <= 0) {
    return (
      <div className="mb-4">
        <NotificationError>
          <HasPolicy policy="use_self_billing">
            <Trans
              i18nKey="playerQuotaMetPurgeOrUpgrade"
              values={{ limit: account.policy.max_users }}
              t={t}
              components={[
                permChecker.hasAccountPermission(Permission.PurgeInactivePlayers) ? <Link to={`/players/purge`} /> : <></>,
                permChecker.hasAccountPermission(Permission.ManageBilling) ? <Link to={`/account/settings`} /> : <></>,
              ]}
            />
          </HasPolicy>
          <HasPolicy not policy="use_self_billing">
            <Trans
              i18nKey="playerQuotaMetPurgeOrContact"
              values={{ limit: account.policy.max_users }}
              t={t}
              components={[
                permChecker.hasAccountPermission(Permission.PurgeInactivePlayers) ? <Link to={`/players/purge`} /> : <></>,
              ]}
            />
          </HasPolicy>
        </NotificationError>
      </div>
    );
  }

  const capacity = Math.floor((THRESHOLDS.find((t) => remainingRatio <= t) || WARNING_THRESHOLD) * 100);
  const percentage = `${capacity}%`;
  return (
    <div className="mb-4">
      <Notification type="warning">
        <HasPolicy policy="use_self_billing">
          <Trans
            i18nKey="playerQuotaClosePurgeOrUpgrade"
            values={{ percentage, limit: account.policy.max_users }}
            t={t}
            components={[
              permChecker.hasAccountPermission(Permission.PurgeInactivePlayers) ? <Link to={`/players/purge`} /> : <></>,
              permChecker.hasAccountPermission(Permission.ManageBilling) ? <Link to={`/account/settings`} /> : <></>,
            ]}
          />
        </HasPolicy>
        <HasPolicy not policy="use_self_billing">
          <Trans
            i18nKey="playerQuotaClosePurgeOrContact"
            values={{ percentage, limit: account.policy.max_users }}
            t={t}
            components={[
              permChecker.hasAccountPermission(Permission.PurgeInactivePlayers) ? <Link to={`/players/purge`} /> : <></>,
            ]}
          />
        </HasPolicy>
      </Notification>
    </div>
  );
};
